<template>
  <div class="relative" v-if="!plan.plan_loading">
    <!--START PRINT PREVIEW-->
    <div
      class="flex justify-end"
      ref="printPreview"
      aria-describedby="Print preview options"
    >
      <button
        class="inline-block py-2 px-3 bg-purple hover:bg-dark-purple rounded-full text-white"
        @click="print('print_ppdp')"
      >
        Print
      </button>
      <button
        class="inline-block py-2 px-3 mx-3 bg-none text-[#510D76] border border-dark-purple hover:bg-dark-purple rounded-full hover:text-white"
        @click="$router.push('/plan')"
      >
        Meet with Mentor
      </button>
    </div>
    <PlanPrint
      v-if="this.plan && student && this.reviewing_mentor"
      id="print_ppdp"
      :plan="this.plan.plans[this.mentor_id]"
      :student="student"
      :mentor="this.reviewing_mentor"
    />
  </div>
  <div v-else>
    <LoadingSpinner aria-describedby="Print preview is loading" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import PlanPrint from "@/views/student/plan/PlanPrint";

export default {
  name: "PlanView",
  components: {
    LoadingSpinner,
    PlanPrint,
  },
  data: function () {
    return {
      mentor_id: null,
    };
  },
  created() {
    this.mentor_id = this.$route.params.mentor_id;
    if (
      this.plan.plans[this.mentor_id] &&
      this.plan.plans[this.mentor_id].ppdp_id === null &&
      this.plan.plans[this.mentor_id].status !== "COMPLETE" &&
      this.$store.state.user_selected_term.name ===
        this.lookup_current_term.name
    ) {
      this.$router.push("/plan/edit");
    }
  },
  watch: {
    acad_level(new_acad_lvl) {
      this.fetchStudentMentors(`L${new_acad_lvl}`)
        .then(this.fetchPlanResource(`L${new_acad_lvl}`))
        .then(() => {
          this.$refs.printPreview.focus();
          if (
            this.plan.plans[this.mentor_id].ppdp_id === null &&
            this.plan.plans[this.mentor_id].status !== "COMPLETE" &&
            this.$store.state.user_selected_term.name ===
              this.lookup_current_term.name
          ) {
            this.$router.push("/plan/edit");
            this.$refs.printPreview.focus();
          }
        });
    },
  },
  methods: {
    print(id) {
      const tw_classes = [
        "header_ppdp",
        "ppdp_print_container",
        "student_title",
        "mentor_title",
        "ethics_mission",
        "experience_ppdp_title",
        "topics_ppdp_title",
        "notes_ppdp_title",
      ];
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: tw_classes,
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      };
      this.$htmlToPaper(id, options);
    },
    ...mapActions(["fetchPlanResource", "fetchStudentMentors"]),
  },
  computed: {
    current_date: function () {
      return new Date().toLocaleDateString();
    },
    reviewing_mentor() {
      return this.student_mentors.find(
        (mentor) =>
          mentor.mentor_id ===
          this.plan.plans[this.mentor_id].reviewing_mentor_id
      );
    },
    ...mapState({
      acad_level: (state) => state.acad_level,
      student: (state) => state.student,
      plan: (state) => state.plan,
    }),
    ...mapGetters(["student_mentors", "lookup_current_term"]),
  },
};
</script>

<style scoped></style>
