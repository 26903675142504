<template>
  <div>
    <div
      id="header_ppdp"
      class="text-[#510D76] font-bold text-3xl border-b border-cool-gray"
    >
      Personal & Professional Development Plan for
      {{ student?.firstname }} {{ student?.lastname }}
    </div>
    <div id="ppdp_print_container" class="py-5">
      <div id="mentor_student_names" class="pb-5">
        <div id="student_name_container" class="pb-2">
          <div
            id="student_title"
            class="text-[#510D76] font-bold text-xl inline-block"
          >
            Student Name:
          </div>
          <div id="student_content" class="px-1 inline-block text-xl">
            {{ student?.firstname }} {{ student?.lastname }}
          </div>
        </div>
        <div>
          <div
            id="mentor_title"
            class="text-[#510D76] font-bold text-xl inline-block"
          >
            Mentor Name:
          </div>
          <div id="mentor_content" class="px-1 inline-block text-xl">
            {{ mentor?.firstname }} {{ mentor?.lastname }}
          </div>
        </div>
      </div>
      <div id="ethics_mission" class="pb-5">
        <div class="text-[#510D76] font-bold text-2xl mb-1.5">
          Personal Ethics Mission
        </div>
        <div class="pb-3">
          Student identifies their first principles, values and goals.
        </div>
        {{ plan?.ethics_statement }}
      </div>
      <div id="experiences_ppdp" class="pb-5">
        <div
          id="experiences_ppdp_title"
          class="text-[#510D76] font-bold text-2xl mb-1.5"
        >
          Select experiences that you are interested in completing
        </div>
        <div class="pb-3">
          Student and mentor identify a minimum of two (2) experiences to
          complete together.
        </div>
        <div>
          <ol id="experiences_ppdp_list" class="list-decimal px-3">
            <li v-for="experience in plan.experiences" :key="experience">
              {{ experience.description }}
            </li>
          </ol>
        </div>
      </div>
      <div id="topics_ppdp" class="pb-5">
        <div
          id="topics_ppdp_title"
          class="text-[#510D76] font-bold text-2xl mb-1.5"
        >
          Debriefing topics
        </div>
        <div class="pb-3">
          Student and mentor identify a minimum of two (2) topics to discuss
          during the year. Student and mentor can discuss: an experiences, a
          template (see list of Templates for suggestions), or other agreed-upon
          professional issue.
        </div>
        <div v-text="plan?.debrief_topics" />
      </div>
      <div id="notes_ppdp" class="pb-5">
        <div
          id="notes_ppdp_title"
          class="text-[#510D76] font-bold text-2xl mb-1.5"
        >
          Additional notes or comments
        </div>
        <div>
          {{ plan?.comments }}
        </div>
      </div>
      <div id="certs_ppdp" class="pb-5">
        <div
          id="certs_ppdp_title"
          class="text-[#510D76] font-bold text-2xl mb-1.5"
        >
          Certifications
        </div>
        <div class="flex flex-col gap-1">
          <div class="flex gap-2">
            <input
              type="checkbox"
              id="one"
              checked="{{plan?.mn_prof_conduct}}"
              disabled
            />
            <label for="one">
                I understand I will be certified as a student observer under Minnesota Supervised Practice Rule 2E, unless my mentor and I are notified otherwise.
            </label>
          </div>
          <div class="flex gap-2">
            <input
              type="checkbox"
              id="two"
              checked="{{plan?.mn_confidentiality_cert}}"
              disabled
            />
            <label for="two">
              I certify that I have read Rule 1.6 of the Minnesota Rules of
              Professional Conduct, and I will maintain confidentiality and will
              ensure that I am identified as a student to any client and
              accepted by them.
            </label>
          </div>
          <div class="flex gap-2">
            <input
              type="checkbox"
              id="three"
              checked="{{plan?.misconduct_disclaimer}}"
              disabled
            />
            <label for="three">
              I understand that I am responsible for the content and accuracy of
              all submissions in connection with Mentor Externship and that any
              submission I make that is false or misleading, and any dishonesty
              or violations of the rules governing my responsibilities in the
              program may constitute "academic misconduct" in accord with the
              Code of Student Responsibility.
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanPrint",
  props: {
    plan: {
      type: Object,
      required: true,
    },
    student: {
      type: Object,
      required: true,
    },
    mentor: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
